<template>
  <div style="width:50%">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-2 mb-lg-2">
        <h1 class="font-size-h1" style="font-weight:bold;color:#000">
          Agency Login
        </h1>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            placeholder="Enter Username"
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            placeholder="Enter Password"
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-center align-items-center"
        >
          <button
            style="font-size: 16px;"
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-20 py-3 my-5 font-size-4"
          >
            Login
          </button>
        </div>
        <div style="text-align:center; color: red" v-if="isRole">
         <p > This role is not allowed to login. </p>
        </div>
        <div
          class="form-group d-flex flex-wrap justify-content-center align-items-center"
        >
          Don`t have one ?
          <a
            href="#"
            style="font-size: 16px;"
            class="text-dark-100 sign-up-link ml-2"
            id="kt_login_forgot"
          >
            Sign Up
          </a>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--begin::Content header-->
    <!-- <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span class="font-weight-bold font-size-3 text-dark-60">
            Don't have an account yet?
        </span>
        <router-link class="font-weight-bold font-size-3 ml-2" :to="{ name: 'register' }">
            Sign Up!
        </router-link>
    </div> -->
    <!--end::Content header-->

    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: "",

        // email: "test.test@gmail.com",
        // password: "Test@1234",
        // email: "affiliate@coupon.ae",
        // password: "A3v4b5n"
      },
     isRole: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.isRole = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, {
            email,
            password,
          })
          // go to which page after successfully login
          .then((res) =>{
              if(res.data.role != 'ACCOUNT_MANAGER'){
                this.isRole = true;
              }
            this.$router.push({
              name: "dashboard",
            })
          }
          );

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>
.form-control.form-control-solid {
  background-color: #f3fcfe;
  border-color: #f3fcfe;
  border-radius: 5px;
  font-size: 16px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #37a4cb !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #37a4cb !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #37a4cb !important;
}

.sign-up-link {
  color: #000;
  font-weight: bold !important;
}
</style>
